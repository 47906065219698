.hero-container {
    max-height: 100vh;
    overflow: hidden;
    .hero-image {
        width: 100%;
    }

    .herotext {
        position: absolute;
        bottom: 50px;
        left: 0;
        max-width: 800px;
        h1 {color: var(--anti-flash-white); text-transform: uppercase;}
        p {font-weight: 500; font-size: 20px; color: var(--anti-flash-white);}
    }
}


// Services Section

.servicesBox {
    position: relative;
    .servicesImg {
        padding-left: 20px;
        padding-right: 20px;
        img {width: 100%;}
    }

    .servicesTxt {
        background-color: var(--anti-flash-dark);
        margin-top: -170px;
        padding-top: 200px;
        h4 {
            font-weight: 500; 
            margin-bottom: 20px; 
            padding-left: 30px; 
            padding-right: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            height: 60px;
        }
        .serviceDes {min-height: 150px; margin-bottom: 30px;padding-left: 30px; padding-right: 30px;}
    }
}

.banner-img {
    img {width: 100%;}
}

.card-price {
    padding-left: 30px; 
    padding-right: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    span.session {text-transform: lowercase;font-size: 16px; font-weight: 500;padding-left: 5px;}
    span.p-simbol {font-size: 14px;font-weight: 700;line-height: 100%;margin-right: 5px;}
}

.hero-container {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0 0 0 / 51%) 100%);
    }
    video {width: 100%; height: 100%;object-fit: fill;}
}

.video-play {
    height: 340px;
}

.package-box {
    &.card {background-color: var(--anti-flash-dark);}
    .card-body {
        h5 {
            text-transform: uppercase; 
            font-weight: 600; 
            margin-bottom: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            height: 50px;
        }
    }
    .package-des {
        min-height: 250px;
        p {font-size: 13px; line-height: 1.6;}
    }
    .card-price {padding-left: 0px; padding-right: 0px;}
}

.nav-tabs.plantypes {
    justify-content: center;
    .nav-link {font-weight: 600; text-transform: uppercase; color: var(--base-color);padding-left: 30px;padding-right: 30px;border-radius: 50px;}
     .nav-link.active {
        background-color: var(--base-color);
        color: var(--anti-flash-white);
     }
}

.studio-img {
    position: relative;
    z-index: 1;
    img {width: 100%;}
}

.studio-txt {
    min-height: 400px;
    margin-top: -300px;
    padding: 350px 50px 50px 50px;
}

.about-logo {max-width: 90%;}

.ab-img {
    img {width: 100%;}
}

.testimonials-section {
    .client-words {font-size: 44px; max-width: 80%; line-height: 1.2; margin-bottom: 50px;}

    .custName {
        span {
            font-size: 18px; font-weight: 500; text-transform: uppercase; position: relative; display: inline-block;
            &:after {content: ""; width: 100px; height: 1px; background-color: var(--base-color); left: 110%; top: 15px; position: absolute;}
        } 
    }
    .owl-nav {
        top: 90%;
        width: auto;
        right: 0;
        button.owl-prev {
            left: inherit;
            right: 64px;
        }
        button.owl-next {
            right: 0;
        }
    }
}

.ablogo {text-align: center;}
.form-control:focus {outline: none; box-shadow: none;}

@media (max-width:1023px) {
    .hero-container {
        max-height: 50vh;
        .herotext {
            bottom: 0;
            p {font-size: 16px;}
        }
    }

    .testimonials-section .client-words {
        font-size: 28px;
        max-width: 100%;
    }
    .ablogo {text-align: left;padding-bottom: 40px;}
    .touchcon {padding-top: 30px;justify-content: start;}
    .quickconnect li a {
        margin: 15px 0;
        display: block;
    }
    form.newsletter-form {
        margin-top: 10px;
    }
    .video-play {
        height: 430px;
    }
    .studio-txt {
        min-height: 400px;
        margin-top: 0;
        padding: 40px 30px 50px 30px;
    }
    .testimonials-section .custName span:after {width: 50px;}
    .testimonials-section .owl-nav {top: 85%;}
}

.sk-ig-profile-usename{
display: none;
}
.sk_instagram_feed_slider{
    background-color: #e3e7ea !important;
}

.swiper-slide {
    background-color: #e3e7ea !important;
}